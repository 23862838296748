<template>
  <div></div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import userApi from '../../api/user'

export default {
    name: 'pointView',
    data(){
        return {
            histories: [],
            pageIndex: 1,
            pageSize: 10,
            count: 0
        }
    },
    computed: {
        ...mapState('client', ['token']),
        ...mapGetters('client', ['isTokenOutOfDate'])
    },
    mounted(){
        if(this.isTokenOutOfDate){
            window.location.href = '/login?callbackUrl=' + encodeURI('/point')
        }
        this.getHistories()
    },
    methods: {
        getHistories(){
            userApi.getClientPointHistory(this.token, this.pageIndex, this.pageSize).then(resp=>{
                this.histories = resp.items
                this.count = resp.count
            })
        },
        generateLinkHref(row){
            console.log(row)
            if(row.reason === 'Shopping'){
                return '/order/' + row.related_id
            }
            return '#'
        },
        handlePageChange(val){
            this.pageIndex = val
            this.getHistories()
        }
    }
}
</script>

<style>
.el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #303133 !important;
}
</style>