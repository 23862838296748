<template>
  <base-web-page>
    <template v-slot:main>
        <el-main style="margin:0 auto;max-width:1300px;width:100%;">
          <el-breadcrumb separator-class="el-icon-arrow-right" style="padding:0px 5px;">
            <el-breadcrumb-item to="/account">My Account</el-breadcrumb-item>
            <el-breadcrumb-item>Points History</el-breadcrumb-item>
          </el-breadcrumb>
          <h1>Points History</h1>
          <div>
              <el-table :data="histories">
                  <el-table-column prop="date_display" label="date"></el-table-column>
                  <el-table-column prop="points" label="points"></el-table-column>
                  <el-table-column prop="reason" label="from"></el-table-column>
                  <el-table-column label="goto order">
                      <template slot-scope="scope">
                          <el-link  type="primary" :href="generateLinkHref(scope.row)">view</el-link>
                      </template>
                  </el-table-column>
              </el-table>
               <el-pagination style="margin-top:20px;" background layout="prev, pager, next" :total="count" :page-size="pageSize" @current-change="handlePageChange"></el-pagination>
          </div>
      </el-main>
    </template>
  </base-web-page>
</template>

<script>
import BaseWebPage from '../../components/base/BaseWebPage.vue'
import ModelPoint from '../viewmodel/ModelPoint.vue'

export default {
    extends: ModelPoint,
    name: 'pointView',
    components: {
        BaseWebPage
    }
}
</script>

<style>
.el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #303133 !important;
}
</style>